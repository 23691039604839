.w-container-pulse{
    width: 12% !important;
}
.w-container-opciones{
    width: 88% !important;
}
@media only screen and (min-width: 600px){
    .h2-size-opciones{
        font-size: 20px;
    }
}
@media only screen and (min-width: 1000px){
    .h2-size-opciones{
        margin-top: .5rem;
    }
}
@media only screen and (min-width: 1200px){
    .h2-size-opciones{
        font-size: 25px;
    }
}