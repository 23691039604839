.portada-max-height{
    max-height: 800px;
}

.btn-download-icon{
    background-color: var(--bs-blue-portinova);
    border-radius: 50%;
    color: white;
    padding: 0.5rem;
}

.top-28{
    top: 28% !important; 
}

.h1-size{
    font-size: 20px;
}

.h3-size{
    font-size: 18px;
}

.p-description-main{
    font-size: 13px;
}

.logo-whatsapp{
    position: fixed;
    bottom: 70px;
    right: 5px;
    background-color: rgb(37,211,102);
    color: #ffff;
    border-radius: 15%;
    
}

/************ responsive ************/
@media only screen and (min-width: 390px){
    .top-28{
        top: 35% !important; 
    }
}

@media only screen and (min-width: 412px){
    .top-28{
        top: 30% !important; 
    }
    .mb-title{
        margin-bottom: 2rem !important;
    }
    .mb-download{
        margin-bottom: 3rem !important;
    }
}

@media only screen and (min-width: 600px){
    .flex-row-edit{
        flex-direction: row !important;
        gap: 0.5rem !important;
    }
    .h1-size{
        font-size: 45px;
    }
    .h3-size{
        font-size: 25px;
    }
    .mb-title{
        margin-bottom: 3rem !important;
    }
    .mb-download{
        margin-bottom: 5rem !important;
    }
    .p-description-main{
        font-size: 25px;
    }
}

@media only screen and (min-width: 835px){
    .top-28{
        top: 35% !important; 
    }
    .mb-title{
        margin-bottom: 4rem !important;
    }
    .mb-download{
        margin-bottom: 8rem !important;
    }
}

@media only screen and (min-width: 1000px){
    .top-28{
        top: 24% !important; 
    }
    .h1-size{
        font-size: 77px;
    }
}