.container-description-suplier{
    background-color: var(--bs-blue-light);
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
}

.btn-go-contact{
    background-color: var(--bs-blue-dark);
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
}

@media only screen and (min-width: 1000px){
    .section-proveider{
        min-height: 37rem;
        max-width: 1380px;
        margin: auto;
    }
    .container-title-proveider{
        display: flex !important;
    }
    .title-proveider{
        margin-top: 7%;
        font-size: 47px;
    }
    .container-description-suplier{
        max-width: 67%;
        min-height: 24rem;
        display: flex;
        align-items: center;
    }
    .container-data-proveider{
        max-width: 53%;
        margin-right: 317px;
    }
    .container-figure-proveider{
        position: absolute;
        display: flex;
        top: -9%;
        justify-content: end;
        right: 0;
    }
    .figure-proveider{
        max-width: 58% !important;
        aspect-ratio: 14 / 13;
    }
    .btn-go-contact{
        position: relative;
        z-index: 1;
    }
}

@media only screen and (min-width: 1280px){
    .section-proveider{
        min-height: 42rem;
    }
    .title-proveider{
        margin-top: 12%;
        font-size: 60px;
    }
    .figure-proveider{
        max-width: 65% !important;
    }
}

@media only screen and (min-width: 1370px){
    .figure-proveider{
        max-width: 68% !important;
    }
}