.bordered-daily{
    border-left: 3px solid var(--bs-blue-light);
    border-right: 3px solid var(--bs-blue-light);
}

@media only screen and (min-width: 600px){
    .flex-no-wrap-edit{
        flex-wrap: nowrap !important;
        gap: 1rem !important;
        align-items: center !important;
    }
}

@media only screen and (min-width: 900px){
    .welcome-portinova{
        width: 55%;
        font-size: 65px;
        align-items: end;
    }
    .container-description-nosotros{
        width: 30%;
    }
}