.section-contact{
    background: linear-gradient(to bottom, white 0%, var(--bs-blue-light) 1%);
}

.overlay-skew{
    background-color: white;
    position: absolute;
    height: 10rem;
    width: 100%;
    -webkit-clip-path: polygon(-35% 0, 30% 70%, 105% 0);
    clip-path: polygon(-35% 0, 30% 70%, 105% 0);
    top: 0;
}

.container-contact{
    margin-top: 8rem;
}

.btn-send-contact{
    background-color: var(--bs-blue-dark);
    border-radius: 20px !important;
    -webkit-border-radius: 20px !important;
    -moz-border-radius: 20px !important;
}

@media only screen and (min-width: 600px){
    .container-data-contact{
        flex-wrap: nowrap !important;
        width: 75%;
        margin: auto;
        gap: 3rem !important;
    }
    .container-question-contact{
        margin-top: 7rem;
    }
}