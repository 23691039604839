.section-products{
    background-image: url("../img/Fondo.png");
    background-repeat: no-repeat;
    background-size: cover;
}

.btn-download-product{
    background-color: var(--bs-blue-dark);
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
}

@media only screen and (min-width: 600px){
    .view-only{
        display: none !important;
    }
    .view-only-two{
        display: block !important;
    }
    .title-products{
        font-size: 9vh;
    }
    .container-description-products{
        text-align: center !important;
        display: flex;
        flex-direction: column;
    }
    .new-carousel-control-prev{
        justify-content: start;
        left: -4%;
    }
    .new-carousel-control-next{
        justify-content: end;
        right: -4%;
    }
}

@media only screen and (min-width: 1000px){
    .view-only-two{
        display: none !important;
    }
    .view-only-tree{
        display: block !important;
    }
    .title-products{
        font-size: 15vh;
    }
}

@media only screen and (min-width: 1280px){
    .view-only-tree{
        display: none !important;
    }
    .view-only-four{
        display: block !important;
    }
}