:root{
  --bs-main: #000000;
  --bs-blue-portinova: #0e70e1;
  --bs-blue-light:#1471e0;
  --bs-blue-dark:#0058bd;
  --bs-dark-gray:#3d3d3d;
}

body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body a{
  text-decoration: none;
  color: inherit !important;
}

body ul{
  list-style: none;
}

.color-portinova{
  color: var(--bs-blue-portinova);
}

.color-blue-light{
  color: var(--bs-blue-light);
}

.color-dark-gray{
  color: var(--bs-dark-gray);
}


/*********** Animations ******************/
.pulse{
  box-shadow: 0 0 0 0 rgb(14, 112, 225);
  -webkit-animation: pulse 2s infinite;
  animation: pulse 2s infinite;
}

@keyframes pulse {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(14, 112, 225, 0.7);
	}
	
	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(14, 112, 225, 0);
	}
	
	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(14, 112, 225, 0);
	}
}

.pulse-green{
  box-shadow: 0 0 0 0 rgb(14, 112, 225);
  -webkit-animation: pulse 2s infinite;
  animation: pulse-green 2s infinite;
}

@keyframes pulse-green {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(37,211,102, 0.7);
	}
	
	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(37,211,102, 0);
	}
	
	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(37,211,102, 0);
	}
}

.writing{
  white-space: nowrap;
  border-right: 4px solid white;
  width: 9ch;
  -webkit-animation: typing 2s steps(9),blink .5s infinite step-end alternate;
	        animation: typing 2s steps(9),blink .5s infinite step-end alternate;
  overflow: hidden;
}

@keyframes typing {
  from { width: 0; }
}
@keyframes blink {
  50% { border-color: transparent; }
}



.shadow-center:hover {
	-webkit-animation: shadow-center 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: shadow-center 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

 @-webkit-keyframes shadow-center {
  0% {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
            box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
  100% {
    -webkit-transform: translateZ(50px);
            transform: translateZ(50px);
    -webkit-box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.474);
            box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.474);
  }
}
@keyframes shadow-center {
  0% {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
            box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
  100% {
    -webkit-transform: translateZ(50px);
            transform: translateZ(50px);
    -webkit-box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.474);
            box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.474);
  }
}


.tracking-in-contract-bck {
	-webkit-animation: tracking-in-contract-bck 2.5s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
	        animation: tracking-in-contract-bck 2.5s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
}

 @-webkit-keyframes tracking-in-contract-bck {
  0% {
    letter-spacing: 1em;
    -webkit-transform: translateZ(400px);
            transform: translateZ(400px);
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    opacity: 1;
  }
}
@keyframes tracking-in-contract-bck {
  0% {
    letter-spacing: 1em;
    -webkit-transform: translateZ(400px);
            transform: translateZ(400px);
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    opacity: 1;
  }
}


.focus-in-expand {
	-webkit-animation: focus-in-expand 0.8s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: focus-in-expand 0.8s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@-webkit-keyframes focus-in-expand {
  0% {
    letter-spacing: -0.5em;
    -webkit-filter: blur(12px);
            filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
            filter: blur(0px);
    opacity: 1;
  }
}
@keyframes focus-in-expand {
  0% {
    letter-spacing: -0.5em;
    -webkit-filter: blur(12px);
            filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
            filter: blur(0px);
    opacity: 1;
  }
}
