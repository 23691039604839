/****** Mobile ************/
.w-40{
    width: 40% !important;
}

.icon-menu{
    background-color: var(--bs-blue-portinova);
    color: var(--bs-white);
    padding: 5px 10px;
    z-index: 4;
}

.container-mobile-header{
    width: 100%;
    height: 100vh;
    transition-timing-function: cubic-bezier(.4,0,1,1);
}

.header-open{
    background-color: rgba(0,0,0,.9);
    color: rgb(209 213 219/1);
    display: flex;
    flex-direction: column;
    left: 0;
    justify-content: center;
    overflow: scroll;
    padding-bottom: 1.75rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 1.75rem;
    position: fixed;
    top: 0;
    transition-duration: .3s;
    z-index: 3 !important;
}

.header-close{
    display: none;
    position: absolute;
    top: 0;
    transition-duration: .5s;
}

.header-pc>li>a{
    font-size: 1.2rem;
}

.header-pc>li>a:hover{
    color: var(--bs-blue-portinova) !important;
}

/************ responsive ************/
@media only screen and (min-width: 600px){
    .icon-menu{
        display: none !important;
    }
    .header-pc{
        display: flex !important;
    }
    .container-footer-logo{
        display: flex !important;
        justify-content: end;
    }
}

@media (max-width: 640px){
    .footer-font{
        font-size: .5rem;
        line-height: .6rem;
    }
}